import React, { useState, useEffect } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import faqData from '../../assets/json/faqData.json';
import { FiPlusCircle } from 'react-icons/fi';
import { AiOutlineMinusCircle } from 'react-icons/ai';
import useIsMobile from '../../hooks/useIsMobile';

const QuestionsDropdown = () => {
  const { pathname } = useLocation();
  const isMobile = useIsMobile();
  const [expand, setExpand] = useState(false);
  useEffect(() => {
    if (pathname && pathname.includes('business-faq')) {
      setExpand(true);
    }
  }, [pathname]);

  const [openQuestionIndex, setOpenQuestionIndex] = useState<number | null>(
    null,
  );

  return (
    <>
      <div
        className={`px-4 md:px-auto md:w-full ${
          pathname.includes('/business-faq') ? 'pb-12' : 'bg-[#F8F9FF]'
        }`}
      >
        {!isMobile && (
          <h2 className='text-2xl md:text-[2.375rem] md:leading-10 pt-10 pb-4 md:pb-8 md:pt-20 font-bold text-[#1133BB] text-center'>
            Questions?
          </h2>
        )}
        <p
          className={`md:w-[70%] md:mx-auto mb-[2rem] md:mb-[4rem] ${
            isMobile ? 'text-left mt-8 text-lg' : 'text-center text-xl'
          }`}
        >
          If you have questions, our FAQ section is here to help. Browse through
          to find detailed answers and helpful information on a variety of
          topics. We hope you'll find everything you need to enhance your
          experience.
        </p>

        <div className='mt-6 md:mx-auto max-w-[67rem]'>
          {(expand
            ? faqData
            : faqData.filter(question => [1, 2, 3].includes(question.id))
          ).map((question, index) => (
            <Quest
              key={question.id}
              el={question}
              isOpen={openQuestionIndex === index}
              toggle={() =>
                setOpenQuestionIndex(openQuestionIndex === index ? null : index)
              }
              isMobile={isMobile}
            />
          ))}
        </div>

        {!expand && (
          <Link to={'/business-faq'}>
            <div className='flex flex-row justify-end pt-8 pb-20 gap-2 items-center max-w-[67rem] mx-auto'>
              <p className='text-primary font-semibold hover:underline'>
                Read more{' '}
              </p>
              <FaExternalLinkAlt className='text-primary font-semibold' />
            </div>
          </Link>
        )}
      </div>
    </>
  );
};

interface QuestProps {
  el: {
    id: number;
    question: string;
    answer: string;
  };
  isOpen: boolean;
  toggle: () => void;
  isMobile?: boolean;
}

// Question component
export const Quest: React.FC<QuestProps> = ({
  el,
  isOpen,
  toggle,
  isMobile,
}) => {
  return (
    <>
      <hr className='w-full' />
      <div
        className='flex flex-row justify-between items-center py-4 cursor-pointer'
        onClick={toggle}
        key={el.id}
      >
        <p
          className={`md:text-xl py-4 md:py-5 pr-4 md:pr-0 text-[#5A5548] font-medium md:font-[400] ${
            isMobile ? 'text-lg' : 'text-xl'
          }`}
        >
          {el.question}
        </p>
        <p
          className='text-[1.65rem] md:text-[2rem] text-[#000000]'
          // style={
          //   isOpen
          //     ? { padding: '0.125rem 0.25rem 0.25rem' }
          //     : { padding: '0.125rem 0.25rem' }
          // }
        >
          {isOpen ? <AiOutlineMinusCircle /> : <FiPlusCircle />}
        </p>
      </div>
      {isOpen && <p className='text-[#5A5548] mb-4 text-base'>{el.answer}</p>}
      <hr className='w-full' />
    </>
  );
};

export default QuestionsDropdown;
