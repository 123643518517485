import React, { useEffect } from 'react';
import QuestionsDropdown from '../../components/questionsDropdown/QuestionsDropdown';

const FaqPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <QuestionsDropdown />
      <div className='mb-8'></div>
      {/* <Footer /> */}
    </>
  );
};

export default FaqPage;
