import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icons } from '../../assets/SVG-Icons';
import { Link } from 'react-router-dom';
import { images } from '../../constants/images';
import { OrganizationDetails } from '../../hooks/useFetchOrganizationDetails';
import Spinner from '../spinner/Spinner';

interface Props {
  data: OrganizationDetails | null;
  loading: boolean;
}

const NavBarLandingPage = ({ data, loading }: Props) => {
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const [isB2C, setIsB2C] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setIsB2C(true);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (data?.orgDisplayName) {
      document.title = data.orgDisplayName;
    }
  }, [data?.orgDisplayName]);

  return (
    <header
      className={`relative md:static flex items-center justify-between py-4 sm:py-8 px-4 md:mx-14 gap-6 smd:gap-4 md:gap-4 ${
        pathname === '/signup' ? 'mb-0 mt-6' : ''
      } ${isMenuOpen ? 'sticky top-0' : ''} sticky top-0 z-50 bg-white`}
      style={{ maxWidth: '100%', overflowX: 'hidden' }}
    >
      {/* Logo Section */}
      <div
        className='w-full md:w-fit lg:w-fit flex justify-between items-center z-50 md:pb-2'
        // style={{ width: 'fit-content' }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <Link className='w-[90%] md:w-auto' to={'/'}>
            {/* {Icons.logo({ width: '300' })} */}
            <img src={data?.logo} alt='' className='w-[11.68rem]' />
          </Link>
        )}

        {/* Hamburger Icon */}
        {pathname !== '/signup' && (
          <button
            className='md:hidden text-3xl z-50'
            onClick={toggleMenu}
            aria-label='Toggle Menu'
          >
            {isMenuOpen ? (
              Icons.closeIcon()
            ) : (
              <svg
                className='w-8 h-8'
                fill='#000000'
                stroke='#000000'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M4 6h16M4 12h16M4 18h16'
                ></path>
              </svg>
            )}
          </button>
        )}
      </div>

      {/* Navigation Menu */}
      {pathname !== '/signup' && (
        <nav
          className={`fixed top-0 left-0 w-full h-full bg-white z-40 transform transition-transform duration-300 md:static md:flex md:items-center md:justify-center md:w-auto ${
            isMenuOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'
          }`}
        >
          <ul className='flex flex-col h-full mt-[10rem] md:mt-0 md:flex-row items-center justify-start md:justify-center gap-1 smd:gap-2 lg:gap-10'>
            {isB2C && (
              <div className='flex xl:gap-12 flex-col md:flex-row gap-8 text-center'>
                <li
                  onClick={() => {
                    if (isMenuOpen) {
                      toggleMenu();
                    }
                    if (currentPath === '/') {
                      document.getElementById('how-it-works')?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      });
                    } else {
                      navigate('/#how-it-works');
                    }
                  }}
                  className='md:pb-0 text-xl smd:mx-0 md:text-base xmd:mx-2 md:mx-0 font-semibold cursor-pointer text-nowrap'
                >
                  How it works
                </li>
                <li
                  onClick={() => {
                    if (isMenuOpen) {
                      toggleMenu();
                    }
                    if (currentPath === '/') {
                      document.getElementById('benefits')?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      });
                    } else {
                      navigate('/#benefits');
                    }
                  }}
                  className='text-xl md:text-base smd:mx-0 xmd:mx-2 md:mx-0 font-semibold cursor-pointer text-nowrap'
                >
                  Benefits
                </li>
                <li
                  onClick={() => {
                    if (isMenuOpen) {
                      toggleMenu();
                    }
                    if (currentPath === '/') {
                      document.getElementById('trust-us')?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      });
                    } else {
                      navigate('/#trust-us');
                    }
                  }}
                  className='md:pb-0 text-xl smd:mx-0 md:text-base xmd:mx-2 md:mx-0 font-semibold cursor-pointer text-nowrap'
                >
                  Trust us
                </li>
                <li
                  onClick={() => {
                    navigate('/business-faq');
                    if (isMenuOpen) {
                      toggleMenu();
                    }
                    // if (currentPath === '/') {
                    //   document.getElementById('how-it-works')?.scrollIntoView({
                    //     behavior: 'smooth',
                    //     block: 'start',
                    //   });
                    // } else {
                    //   navigate('/#how-it-works');
                    // }
                  }}
                  className='pb-12 md:pb-0 text-xl smd:mx-0 md:text-base xmd:mx-2 md:mx-0 font-semibold cursor-pointer'
                >
                  FAQ
                </li>
              </div>
            )}
          </ul>
        </nav>
      )}
      <div className='hidden md:flex flex-col md:flex-row min-w-[15rem] gap-4 max-w-72 items-center'>
        <Link to={data?.appDeepLink + ''} target='_blank'>
          <img src={images.iosLogo} alt='' />
        </Link>
        <Link to={data?.googleDeepLink + ''} target='_blank'>
          <img src={images.androidLogo} alt='' />
        </Link>
      </div>
    </header>
  );
};

export default NavBarLandingPage;
