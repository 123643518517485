import React from 'react';
import SectionCard from './SectionCard';
import tab1 from '../../assets/images/b2c/accordion-1.png';
import tab2 from '../../assets/images/b2c/accordion-2.png';
import tab3 from '../../assets/images/b2c/accordion-3.png';
import tabMobile1 from '../../assets/images/b2c/accordion-mobile1.png';
import tabMobile2 from '../../assets/images/b2c/accordion-mobile2.png';
import tabMobile3 from '../../assets/images/b2c/accordion-mobile3.png';

const TabbedSections: React.FC = () => {
  const sections = {
    images: {
      desktop: [tab1, tab2, tab3],
      mobile: [tabMobile1, tabMobile2, tabMobile3],
    },
    titles: ['Go out to eat.', 'Shop till you drop.', 'Travel smart and save.'],
    descriptions: [
      "With over 20,000 local favorites at your hands and the best national brands we all know and love. It's easy to find somewhere you can get a bite and make a buck.",
      "With access to hundreds of top favorite brands, it's never been easier to find a place, online or offline, where you can get what you need and save some cash.",
      "Explore a world of options with access to top travel brands and destinations, it's never been easier to book your next adventure and keep more money in your pocket.",
    ],
    tabs: ['Dining', 'Retail', 'Travel'],
  };

  return (
    <div className='space-y-10'>
      <div className=''>
        <h2 className='text-2xl md:text-[2.375rem] md:leading-3 mt-10 md:mt-24 mb-2 md:mb-6 text-center font-bold text-[#1133BB]'>
          Ways to save more.
        </h2>
        <p className='mt-6 mb-12 text-lg sm:text-[1.7rem] font-medium text-center px-2 max-w-[43rem] mx-auto'>
          There are so many ways (and places) you can fill your wallet with us.
        </p>
      </div>
      <SectionCard
        images={sections.images}
        titles={sections.titles}
        descriptions={sections.descriptions}
        tabs={sections.tabs}
      />
    </div>
  );
};

export default TabbedSections;
