import { useLocation } from 'react-router-dom';

const useIsMobile = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isMobile = queryParams.get('ismobile') === 'true';

  return isMobile;
};

export default useIsMobile;
