import { useState, useEffect, useRef } from 'react';
import envConfig from '../utils/config';

export interface OrganizationDetails {
  orgDisplayName: string;
  logo: string;
  b2cLink: string;
  googleDeepLink: string;
  appDeepLink: string;
  qrCode: string;
  logoMobile: string;
}

const ApiKey = envConfig.REACT_APP_ADMIN_X_API_KEY;
export const useFetchOrganizationDetails = () => {
  const [data, setData] = useState<OrganizationDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const isFetched = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      const fullUrl = window.location.href;
      const urlWithoutProtocol = fullUrl
        .replace(/^https?:\/\//, '')
        .replace(/\/$/, '');

      try {
        const res = await fetch(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/usr/p/g/b/l`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': ApiKey,
            },
            body: JSON.stringify({
              b2cLink: urlWithoutProtocol,
            }),
          },
        );

        if (!res.ok) {
          throw new Error('Failed to fetch organization details');
        }

        const data = await res.json();
        setData(data);
      } catch (error: any) {
        setError(error.message || 'Something went wrong');
      } finally {
        setLoading(false);
      }
    };
    if (
      !isFetched.current &&
      !data &&
      !window.location.href.includes('thankyou')
    ) {
      isFetched.current = true;
      fetchData();
    }
  }, [data]);

  return { data, loading, error };
};
