import Footer from '../../components/footer/Footer';
import { images } from '../../constants/images';
import React, { useEffect } from 'react';
import { OrganizationDetails } from '../../hooks/useFetchOrganizationDetails';

interface Props {
  data: OrganizationDetails | null;
}

const AboutUs = ({ data }: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className='bg-[#F8F9FF]'>
        <div className='flex flex-col-reverse md:flex-row items-center py-8 md:px-8 gap-8 md:gap-0'>
          <div className=' md:px-12 px-4'>
            <p className='text-xl font-[400]'>
              With over 20 years of experience in the cashback and loyalty
              industry, Savers Club is a trusted leader in delivering innovative
              solutions that drive customer engagement and satisfaction.{' '}
            </p>
            <br />
            <p className='text-xl font-[400]'>
              Our journey began with a passion for helping businesses reward
              their customers, and today, we continue to set the standard with
              our advanced technology and commitment to excellence.
            </p>
          </div>
          <img src={images.AboutUsBanner1} className='px-4 md:px-0' alt='' />
        </div>
      </div>
      <div className='container-fluid md:container mt-8 md:mt-12 mx-auto'>
        <p className='text-xl text-center font-[400] leading-[38px]'>
          Central to our success is our state-of-the-art technology, designed
          with the highest standards of safety and security. Enhanced by
          advanced artificial intelligence, it enables us to deliver highly
          personalised and frictionless experiences that resonate with our
          users. By leveraging AI, we continuously refine our offerings,
          ensuring that our customers receive the most relevant and rewarding
          opportunities available.
        </p>
      </div>

      <div className='flex flex-col md:flex-row my-12 md:my-[5rem] md:px-12 items-center gap-8 md:gap-0'>
        <img src={images.AboutUsBanner2} className='px-4 md:px-0' alt='' />
        <div className='px-4 md:px-12'>
          <p className='text-xl font-[400]'>
            {' '}
            Headquartered in Utah, USA with additional offices in Dubai, UAE,
            and Bangalore, India, our team comprises a diverse group of
            professionals with the multi-disciplinary backgrounds required for
            managing partner relationships, understanding consumer behavior, and
            delivering the most optimal B2B2C product technology.
          </p>
          <br />
          <p className='text-xl font-[400]'>
            We are more than just a service provider; we are your partners in
            success, committed to helping you build stronger relationships with
            your customers through exceptional cashback and loyalty programs.{' '}
          </p>
        </div>
      </div>
      <Footer data={data} />
    </div>
  );
};

export default AboutUs;
