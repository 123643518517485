import React, { useState } from 'react';

interface SectionCardProps {
  images: { desktop: string[]; mobile: string[] };
  titles: string[];
  descriptions: string[];
  tabs: string[];
}

const SectionCard: React.FC<SectionCardProps> = ({
  images,
  titles,
  descriptions,
  tabs,
}) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [isArrow, setIsArrow] = useState(false);
  return (
    <>
      <div className='relative w-[95%] mx-auto my-6 hidden md:block'>
        <div className='absolute top-4 flex justify-around items-center z-10 w-full mt-16'>
          {tabs.map((tab, index) => (
            <button
              key={tab}
              onMouseEnter={() => setActiveTab(index)}
              className={`px-10 py-5 text-3xl font-extrabold ${
                activeTab === index && isArrow
                  ? 'text-black bg-white rounded transition-colors duration-500 '
                  : 'text-white'
              }`}
            >
              {tab}
            </button>
          ))}
        </div>

        <div className='w-full h-[37.5rem] relative overflow-hidden shadow-lg'>
          {images.desktop.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={titles[index]}
              className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-500 ${
                activeTab === index ? 'opacity-100' : 'opacity-0'
              }`}
            />
          ))}

          <div className='absolute inset-0 flex z-20'>
            {/* First Section */}
            <div
              className='w-1/3 h-full relative border-r border-gray-500 cursor-pointer'
              onMouseEnter={() => {
                setActiveTab(0);
                setIsArrow(true);
              }}
              onMouseLeave={() => setIsArrow(false)}
            >
              {/* {activeTab === 0 && isArrow && <LeftArroIcon />} */}
            </div>

            {/* Second Section */}
            <div
              className='w-1/3 h-full relative border-r border-gray-500 cursor-pointer'
              onMouseEnter={() => {
                setActiveTab(1);
                setIsArrow(true);
              }}
              onMouseLeave={() => setIsArrow(false)}
            >
              {/* {activeTab === 1 && isArrow && <LeftArroIcon />} */}
            </div>

            {/* Third Section */}

            <div
              className='w-1/3 h-full relative border-r border-gray-500 cursor-pointer'
              onMouseEnter={() => {
                setActiveTab(2);
                setIsArrow(true);
              }}
              onMouseLeave={() => setIsArrow(false)}
            >
              {/* {activeTab === 2 && isArrow && <LeftArroIcon />} */}
            </div>
          </div>

          {/* Content */}
          <div className='absolute bottom-4 text-white z-10 px-28 pb-24'>
            <h2 className='text-3xl font-black'>{titles[activeTab]}</h2>
            <p className='mt-2 text-2xl font-medium'>
              {descriptions[activeTab]}
            </p>
          </div>
          <div className='absolute inset-0 bg-black opacity-50'></div>
        </div>
      </div>

      {/* Accordion for Mobile */}
      <div className='md:hidden mx-4'>
        {tabs.map((tab, index) => (
          <div key={tab} className='mb-[0.65rem]'>
            <button
              onClick={() => setActiveTab(index)}
              className='w-full text-left text-xl font-bold py-6 px-7 bg-gray-200'
            >
              {tab}
            </button>
            {activeTab === index && (
              <img
                src={images.mobile[index]}
                alt={titles[index]}
                className='w-fit object-contain mb-4'
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default SectionCard;
